import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore, useSubMe, useSubPosses, useSubProfiles, useInviteURL, useSubInvites } from '../hooks'
import FlashScreen from './FlashScreen'
import { isMobileDevice } from '../lib/sharing'

import { VOTE_2020_CAMPAIGN, AUTH_ROUTES, UNIVERAL_ROUTES, MOBILE_ONLY_ROUTES } from '../constants/posse'
import { SKIP_AUTH_REDIRECT, SET_AUTH_REDIRECT } from '../constants/kv'
import { useRouter } from 'next/router'

import ErrorDialog from './ErrorDialog'

const getAnchor = (url) => {
  const urlParts = url.split('/#')
  return urlParts.length > 1 ? urlParts[1] : null
}

function Auth({ children, path }) {
  const [{ auth }] = useStore()
  // setPath includes query args in the new path and calls router.push
  const [, setPath] = useInviteURL()
  const router = useRouter()

  const { isAuthing, isAuthed, initted, error } = auth
  useSubMe()
  useSubPosses(VOTE_2020_CAMPAIGN)
  useSubProfiles()
  useSubInvites()

  const isAuthRoute = AUTH_ROUTES.includes(path)
  const isUniversalRoute = UNIVERAL_ROUTES.includes(path) || path.match(/state/g)
  // const isUniversalRoute = true
  const isMobileRoute = MOBILE_ONLY_ROUTES.includes(path)
  const mobile = isMobileDevice
  const route = path.toLowerCase().slice(1)
  let redirect = route === 'vote' ? '/vote' : '/'
  if (path === '/v1') {
    redirect = mobile ? '/v1' : '/desktop'
  }

  useEffect(() => {
    // No redirect on universal route
    console.log('isMobile', isMobileDevice)
    if (isUniversalRoute) return
    if (!mobile && isMobileRoute && typeof window !== 'undefined') {
      console.log('redirecting to ' + redirect)
      setPath(redirect)
    }
  }, [isMobileDevice, typeof window])

  useEffect(() => {
    const asyncHelper = async () => {
      if (!initted || isAuthing) {
        return
      }
      const target = getAnchor(router.asPath)
      if (isAuthed && !isAuthRoute) {
        // Check for a root level anchor tag
        if (target && mobile) {
          console.log('Auth.redirect', `/dashboard#${target}`)
          setPath(`/dashboard#${target}`)
        }
        if (mobile && !isUniversalRoute) {
          const skipRedirect = sessionStorage.getItem(SKIP_AUTH_REDIRECT)
          if (skipRedirect) {
            console.log('Auth.skipRedirect', skipRedirect)
            sessionStorage.removeItem(SKIP_AUTH_REDIRECT)
            return
          }
          const setRedirect = sessionStorage.getItem(SET_AUTH_REDIRECT)
          if (setRedirect) {
            console.log('Auth.setRedirect', setRedirect)
            sessionStorage.removeItem(SET_AUTH_REDIRECT)
            setPath(setRedirect)
            return
          }
          setPath(`/dashboard`)
          return
        } else {
          if (isUniversalRoute) {
            return
          } else {
            setPath(redirect)
            return
          }
        }
      }
      if (!isAuthed && !isUniversalRoute && path !== '/') {
        setPath(redirect)
      }
      if (isMobileRoute && !mobile) {
        setPath(redirect)
      }
    }
    asyncHelper()
  }, [isAuthRoute, isAuthing, isAuthed, error])

  // only show the auth error modal if it's an auth route
  if (isAuthRoute && error) {
    return (
      <ErrorDialog
        title="Auth Error"
        message={
          "We've run into a problem with your phone's internet connection or web browser.  " +
          'Please first check that you have signal. If you do, then close and re-open your browser. ' +
          "If you don't know how, then turn off and on your phone."
        }
      />
    )
  }

  if ((path === '/join' && isAuthing) || (path === '/join' && isAuthed)) {
    return <FlashScreen />
  }
  if (path === '/' || path === '/vote' || isUniversalRoute) return children

  if (!initted || isAuthing) return <FlashScreen />

  // if ((isAuthRoute && !initted) || isAuthing) return <FlashScreen />

  if (isAuthRoute && !isAuthed) {
    return null
  }

  return children
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  path: PropTypes.string.isRequired,
}

export default Auth
